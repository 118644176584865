import React, {useState, useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
//import { navigate } from "gatsby";
import UserWrapper from '../userWrap';
import Header from '../header/index';
//import CookiePolicy from "../cookie-policy";
//import qs from "query-string";
import Footer from '../footer';
import {
  setShowModal,
  getShowModal,
  getUserExists,
  setImpactClickIdCookie,
  setImpactMediaPartnerIdCookie,
  removeImpactMediaPartnerIdCookie,
  removeImpactClickIdCookie,
  getRawCookie,
  removeDocumentReferrer,
  setDocumentReferrer,
  logOut,
  getUserEmail,
  getNewUser,
  setNewUser,
  setShowAppFooter,
  getShowAppFooter,
  setShowDemoModalonExit,
  getShowDemoModalonExit,
} from '../../helpers';
import SubscriptionModal from '../../components/subscriptionModal';
import CustomModal from '../customModal/index';
import AndroidIosApp from '../android-ios-app';
import Login from '../../components/login/index';
import RequestDemo from '../../components/demo-request';
import Registration from '../../components/registration/index';
import ForgotPassword from '../../components/forgot-password/index';
import {faSignOutAlt, IconDefinition, faChartLine} from '@fortawesome/free-solid-svg-icons';
import {MenuItem} from '../../types';
import {navigate} from 'gatsby';
import {useMutation, useQuery} from '@apollo/react-hooks';
import {LOG_OUT} from '../../graphql/mutations';
import {GET_USER_WATCH_SETTINGS_QUERY, ME} from '../../graphql/queries';
import {openCalendly} from '../../helpers/trackingEvents';
import CookieConsentPopup from '../cookie-popup';
import StickyMessage from '../sticky-message';

const Layout = ({children, refetchUser, promoPage, annualPromo, noBg, home, specialLtd}: any) => {
  const [showIcon, setShowIcon] = useState(false);
  const [show, setShow] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showDemo, setShowDemo] = useState(false);
  const [demoSuccess, setDemoSuccess] = useState(false);
  const [showRegistration, setShowRegistration] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showSubItems, setShowSubItems] = useState(false);
  //const [promoCode, setPromoCode] = useState("");
  const [email, setEmail] = useState('email');
  const [handleCloseLoginModal, setHandleCloseLoginModal] = useState<boolean>(false);
  const [handleCloseDemoModal, setHandleCloseDemoModal] = useState<boolean>(false);
  const [handleCloseRegModal, setHandleCloseRegModal] = useState<boolean>(false);
  const [timeoutId, setTimeoutId] = useState<any>('');
  const [log_out] = useMutation(LOG_OUT);

  const {data: hexowatchUserInfo} = useQuery(GET_USER_WATCH_SETTINGS_QUERY, {
    fetchPolicy: 'no-cache',
  });

  const logOutHandler = async (id: number): Promise<void> => {
    const {data} = await log_out({variables: {id, device_key: getRawCookie('device_key')}});
    if (data && data.UserOps && data.UserOps.logout && !data.UserOps.logout.error) {
      logOut();
      setEmail(getUserEmail() || '');
      refetch();
    }
  };

  // const logOutHandler = (id: number) => {
  //   logOut();
  //   setEmail(getUserEmail() || '');
  // };

  const HeaderMenuItems = (isLoggedIn: boolean, firstName?: string, id?: number, src?: string) => {
    const headerMenuItems: {
      name?: string;
      linkTo?: string | null;
      className?: string;
      icon?: IconDefinition | string;
      img?: boolean;
      src?: string;
      target?: string;
      subItems?: Array<MenuItem>;
      showSubItems?: boolean;
      func?: () => void | Promise<void> | any;
      subMenu?: any;
    }[] = [
      {
        name: 'Why Hexowatch',
        linkTo: '/about',
        className: 'nav-link',
      },
      {
        name: 'Advanced monitoring',
        linkTo: '/advanced-monitoring',
        className: 'nav-link',
      },
      {name: 'Pricing', linkTo: '/pricing', className: 'nav-link'},
      {
        name: 'Academy',
        linkTo: 'https://hexowatch.com/academy/',
        className: 'nav-link',
        subMenu: [
          {
            name: 'Insider Guides',
            linkTo: 'https://hexowatch.com/academy/category/insider-guides/',
            className: 'nav-link',
          },
          {
            name: 'News',
            linkTo: 'https://newsletter.hexact.io/',
            className: 'nav-link',
          },
          {
            name: 'Tutorials',
            linkTo: 'https://hexowatch.com/academy/hexowatch-tutorials/',
            className: 'nav-link',
          },
        ],
      },
    ];

    if (!isLoggedIn) {
      headerMenuItems.push(
        {
          name: 'Login',
          linkTo: `/login`,
          className: 'nav-link header_login_btn d-lg-none',
        },
        {
          name: 'Get started',
          linkTo: '/registration',
          className: 'nav-link software_banner_btn header_registration_btn d-lg-none',
          icon: undefined,
        },
        {
          name: 'Request a demo',
          className: 'nav-link software_banner_btn outline-white header_registration_btn d-lg-none',
          icon: undefined,
          func: () => openCalendly(),
        },
      );
    } else {
      headerMenuItems.push({
        name: firstName,
        className: 'nav-link loggedin-items d-lg-none',
        img: true,
        src: src,
        func: () => setShowSubItems(!showSubItems),
        showSubItems: showSubItems,
        subItems: [
          {
            name: 'Dashboard',
            className: 'nav-link loggedin-items d-lg-none',
            linkTo:
              global &&
              global.window &&
              global.window.location &&
              global.window.location.host &&
              global.window.location.host.includes('37.186.119.181')
                ? 'http://37.186.119.181:3276'
                : `https://dash.hexowatch.com`,
            icon: faChartLine,
          },
          {
            name: 'Sign out',
            className: 'nav-link loggedin-items d-lg-none',
            icon: faSignOutAlt,
            func: () => id && logOutHandler(id),
          },
        ],
      });
    }

    return headerMenuItems;
  };

  const handleDetectPosition = (e: any) => {
    const hexowatchUserLocal = hexowatchUserInfo?.UserWatchSettings?.get;
    const freeOrNoUser =
      hexowatchUserLocal && (hexowatchUserLocal.pricing_package === 'FREE' || !hexowatchUserLocal.pricing_package);
    if (
      e.clientY < 20 &&
      getShowDemoModalonExit() !== 'false' &&
      freeOrNoUser &&
      !document.querySelector('.go-to-login-page') &&
      !document.querySelector('.show-tooltip') &&
      (global?.window?.location?.href?.includes('pricing') || global?.window?.location?.href?.includes('promo')) &&
      getRawCookie('cookie_preference')
    ) {
      setShowDemoModalonExit('false');
      openCalendly();
    } else if (
      e.clientY < 20 &&
      getShowModal() !== 'false' &&
      getUserExists() !== 'true' &&
      !document.querySelector('.go-to-login-page') &&
      !document.querySelector('.show-tooltip') &&
      !(global?.window?.location?.href?.includes('pricing') || global?.window?.location?.href?.includes('promo')) &&
      getRawCookie('cookie_preference')
    ) {
      setShowModal('true');
      setShow(true);
    }
  };

  useEffect(() => {
    global.document && global.document.addEventListener('mousemove', handleDetectPosition);
    return () => {
      global.document.removeEventListener('mousemove', handleDetectPosition);
    };
  }, [hexowatchUserInfo]);

  const {data: userData, refetch} = useQuery(ME, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (
      global.window &&
      global.window.location &&
      global.window.location.hash &&
      global.window.location.hash === '#login' &&
      global.window.location.pathname === '/'
    ) {
      navigate('/login');
    }
    if (
      global.window &&
      global.window.location &&
      global.window.location.hash &&
      global.window.location.hash === '#registration' &&
      global.window.location.pathname === '/'
    ) {
      const urlParams = new URLSearchParams(global.window.location.search);
      let referral = urlParams.get('referralCode');
      referral ? navigate(`/registration/?referralCode=${referral}`) : navigate('/registration');
    }
    // if (
    //   global.window &&
    //   global.window.location &&
    //   global.window.location.hash &&
    //   global.window.location.hash === '#demo'
    // ) {
    //   setShowDemo(true);
    // }
    if (
      global &&
      global.window &&
      global.window.location &&
      !global.window.location.pathname.includes('pricing') &&
      !global.window.location.pathname.includes('registration') &&
      !global.window.location.pathname.includes('login') &&
      !global.window.location.pathname.includes('verify-email')
    ) {
      localStorage.removeItem('checkout-data');
    }
  }, [global.window && global.window.location && global.window.location.hash]);

  useEffect(() => {
    refetch();
  }, [refetchUser]);

  // const [generateApolloData] = useLazyQuery(GENERATE_APOLLO_DATA);

  useEffect(() => {
    // Setting document referrer
    const referrer =
      typeof document !== 'undefined' &&
      document &&
      document.referrer &&
      document.referrer.indexOf('https://hexowatch.com') === -1 &&
      document.referrer;
    if (referrer && getRawCookie('document_referrer')) {
      removeDocumentReferrer();
    }
    referrer && setDocumentReferrer(referrer);

    // Setting Impact irclickid in cookies
    const url = global.window && global.window.location && global.window.location.href;
    if (url && url.indexOf('irclickid=') > -1) {
      if (getRawCookie('__irClickId')) {
        removeImpactClickIdCookie();
      }
      const id = url.slice(url.indexOf('irclickid=') + 10);
      const idFin = id.slice(0, id.indexOf('&'));
      setImpactClickIdCookie(idFin);
      removeImpactMediaPartnerIdCookie();
    } else if (url && url.toLocaleLowerCase().indexOf('mediapartnerid=') > -1) {
      if (getRawCookie('__mediaPartnerId')) {
        removeImpactMediaPartnerIdCookie();
      }
      const id = url.slice(url.toLocaleLowerCase().indexOf('mediapartnerid=') + 15);
      const idFin = id.slice(0, id.indexOf('&'));
      setImpactMediaPartnerIdCookie(idFin);
      removeImpactClickIdCookie();
    }
  }, []);

  useEffect(() => {
    if (getNewUser()) {
      if (window.location.href.includes('hexowatch.com')) {
        //@ts-ignore
        window.dataLayer.push({
          event: `accountRegistration-hexowatch`,
          registrationOrderId: new Date().toISOString(),
        });
        //@ts-ignore
        window.dataLayer.push({
          event: `registration-hexowatch`,
          ecommerce: {
            registration: {
              actionField: {
                id: `Registration_${new Date().toISOString()}`,
              },
            },
          },
        });
        setNewUser(false);
        // generateApolloData();
      }
    }
    const el = document && document.getElementsByTagName('cloudflare-app');
    if (getShowAppFooter()) {
      setShowIcon(false);
    } else if (getShowAppFooter() === null && el && !el.length) {
      setShowIcon(true);
    }
  }, []);

  useEffect(() => {
    if (window.location.href.includes('PayerID=') || window.location.href.includes('&ba_token=')) {
      window.close();
    }
  }, []);

  const closeModal = () => {
    timeoutId && clearTimeout(timeoutId);
    if (handleCloseLoginModal || handleCloseRegModal || handleCloseDemoModal) {
      if (localStorage.getItem('checkout-data')) {
        navigate('/pricing/checkout/');
      } else {
        if (window.location.host.includes('localhost')) {
          window.location.href = 'http://localhost:3000';
        } else if (window.location.host.includes('37.186.119.181')) {
          window.location.href = 'http://37.186.119.181:3276/hexowatch';
        } else {
          window.location.href = 'https://dash.hexowatch.com/hexowatch';
        }
      }
      return;
    }

    setShowLogin(false);
    setShowRegistration(false);
    setShowDemo(false);
    setDemoSuccess(false);
  };
  const isMobileDevice = useMediaQuery({maxWidth: 1024});

  const handleRemoveId = (): void => {
    setShowAppFooter('true');
    setShowIcon(false);
  };

  return (
    <>
      <UserWrapper
        withoutUser={() => {
          return (
            <>
              <Header
                menuItems={HeaderMenuItems(false)}
                refetchUser={() => refetch()}
                promoPage={promoPage}
                annualPromo={annualPromo}
                noBg={noBg}
                specialLtd={specialLtd}
              />
              <SubscriptionModal
                isModalOpen={
                  !show || window.location.hash === '#login' || window.location.hash === '#registration' ? false : true
                }
              />
            </>
          );
        }}
        withLoading={() => {
          return (
            <Header
              menuItems={HeaderMenuItems(false)}
              loading
              refetchUser={() => refetch()}
              promoPage={promoPage}
              annualPromo={annualPromo}
              noBg={noBg}
              specialLtd={specialLtd}
            />
          );
        }}
        withUser={user => (
          <Header
            user={user}
            menuItems={HeaderMenuItems(true, user.firstName, +user.id)}
            refetchUser={() => refetch()}
            promoPage={promoPage}
            annualPromo={annualPromo}
            noBg={noBg}
            specialLtd={specialLtd}
          />
        )}
      />

      <div className={`content-wrapper ${home ? 'with-bg' : ''}`}>
        {showLogin && (
          <CustomModal ShowPopup={showLogin} setShowPopup={closeModal}>
            <Login
              setShowLogin={setShowLogin}
              setShowRegistration={setShowRegistration}
              setShowForgotPassword={setShowForgotPassword}
              setHandleCloseLoginModal={setHandleCloseLoginModal}
            />
          </CustomModal>
        )}
        {showDemo && (
          <CustomModal ShowPopup={showDemo} setShowPopup={closeModal} className="demo-request" fitContent={demoSuccess}>
            <RequestDemo
              setHandleCloseDemoModal={setHandleCloseDemoModal}
              setDemoSuccess={setDemoSuccess}
              setTimeoutId={setTimeoutId}
            />
          </CustomModal>
        )}
        {showRegistration && (
          <CustomModal ShowPopup={showRegistration} setShowPopup={closeModal}>
            <Registration
              setShowLogin={setShowLogin}
              setShowRegistration={setShowRegistration}
              //promoCode={promoCode.slice(0, promoCode.length - 1)}
              setHandleCloseRegModal={setHandleCloseRegModal}
            />
          </CustomModal>
        )}
        {showForgotPassword && (
          <CustomModal
            ShowPopup={showForgotPassword}
            setShowPopup={setShowForgotPassword}
            showBackBtn
            onBackBtnClick={() => {
              setShowLogin(true);
              setShowForgotPassword(false);
            }}
          >
            <ForgotPassword setShowForgotPassword={setShowForgotPassword} />
          </CustomModal>
        )}
        {children}
      </div>
      {/* {global.window && global.window.location && global.window.location.href && isMobileDevice && showIcon && (
        <AndroidIosApp closeHandler={handleRemoveId} closeIconHandler={handleRemoveId} />
      )} */}
      <Footer />
      <CookieConsentPopup />
      {/* <StickyMessage /> */}
    </>
  );
};

export default Layout;
