import React from 'react';
import {Link} from 'gatsby';
import './styles.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Translate} from '../../components/translations';
import {faFacebookF, faYoutube, faLinkedin, faTiktok, faInstagram} from '@fortawesome/free-brands-svg-icons';
// import googleplay from '../../images/googleplay.png';
// import appstore from '../../images/appstore.png';
import {twitterIcon} from '../../images/svg-icons';
// import poweredByHexometer from '../../images/Hexometer-Logo(Powered by).svg';

const footerMenuItems = [
  // {
  //   name: 'Integrations',
  //   subItems: [
  //     { name: 'Telegram Integration', linkTo: '/telegrambot' },
  //     { name: 'Slack Integration', linkTo: '/slackbot' },
  //     { name: 'Zapier Integration', linkTo: '/zapier' },
  //     { name: 'Pabbly Connect Integration', linkTo: '/pabbly' },
  //   ],
  // },
  {
    name: 'Menu',
    subItems: [
      {name: 'Why we built Hexowatch', linkTo: '/about'},
      {name: 'Advanced monitoring', linkTo: '/advanced-monitoring'},
      // {name: 'Team', linkTo: 'https://hexact.io/leadership/'},
      {name: 'Roadmap', linkTo: '/roadmap'},
      {
        name: 'Changelog',
        linkTo: 'https://hexowatch.com/academy/hexowatch-changelog/',
      },
      // {name: 'Affiliate program', linkTo: '/affiliate'},
      {name: 'Hexowatch use cases', linkTo: '/use-cases'},
      {name: 'Software integrations', linkTo: '/software-integrations'},
      {name: 'Pricing', linkTo: '/pricing'},
      {name: 'Academy', linkTo: 'https://hexowatch.com/academy/'},
      {
        name: 'Tutorials',
        linkTo: 'https://hexowatch.com/academy/hexowatch-tutorials/',
        target: '_blank',
        ariaLabel: 'hexowatch blog',
      },
      {name: 'FAQ', linkTo: '/faq'},
      // {name: 'Career', linkTo: 'https://hexact.io/careers', target: '_blank'},
      {name: 'Contact us', linkTo: '/contact'},
      // { name: 'API documentation', linkTo: '/api-documentation' },
    ],
  },
];

const Footer = () => {
  return (
    <footer className="footer_area footer_area_five f_bg" id="footer">
      <div className="footer_chat">
        <div className="container">
          <h2 className="f_p f_size_14 t_color f_400 l_height30">
            <span className="f_p f_size_20 f_600 t_color pr_20">
              <Translate name="FOOTER_UPPER_TEXT_LEFT" />
            </span>
            <a
              //@ts-ignore
              href=""
              onClick={e => {
                e.preventDefault();
                //@ts-ignore
                window.Intercom('show');
              }}
            >
              Chat now
            </a>{' '}
            or Email to{' '}
            <a href="mailto:support@hexowatch.com" target="_blank" rel="noopener">
              support@hexowatch.com
            </a>
          </h2>
        </div>
      </div>
      <div className="footer_top sec_pad">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 p_0">
              <div className="f_widget company_widget">
                <div className="widget-wrap t_color4">
                  {/* <div className="f_widget company_widget pr_20">
                    <a href="https://hexometer.com" title="Website Audit and Monitoring" target="_blank">
                      <img src={poweredByHexometer} alt="Website Audit and Monitoring" width={147} />
                    </a>
                  </div> */}
                  <p>Hexowatch - Go beyond page change monitoring.</p>
                  <p>
                    Monitor updates on any website and get notified when visual, content, keyword, technology stack,
                    HTML, availability, price or WHOIS changes are detected.
                  </p>
                  <p>
                    24/7 AI-assisted website monitoring and archiving. Receive notifications when important changes are
                    detected on your landing pages, competitors, clients, partners, or industry websites.
                  </p>
                  <p>Get started in minutes - no software, proxies or programming required</p>
                </div>
              </div>
            </div>
            {footerMenuItems.map(
              (item): JSX.Element => (
                <>
                  <div key={item.name} className="col-lg-3 col-md-6 col-sm-6 footer-menu-item">
                    <div className="f_widget about-widget pl_40">
                      <div className="footer-sub-items ">
                        {item.name && item.name.length > 0 && (
                          <h3 className="f-title footer-menu-item-title f_600 t_color f_size_18 mb_20">{item.name}</h3>
                        )}
                        {item.subItems && (
                          <ul className="list-unstyled f_list">
                            {' '}
                            {item.subItems.map(
                              (subItem): JSX.Element => (
                                <li key={subItem.name}>
                                  {subItem.linkTo && subItem.linkTo.indexOf('://') > -1 ? (
                                    <a href={subItem.linkTo} target={subItem.target}>
                                      {subItem.name}
                                    </a>
                                  ) : (
                                    <Link
                                      to={subItem.linkTo || ''}
                                      state={
                                        subItem.linkTo && subItem.linkTo === '/login' ? {goBack: true} : {goBack: false}
                                      }
                                    >
                                      {subItem.name}
                                    </Link>
                                  )}
                                </li>
                              ),
                            )}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ),
            )}
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="f_widget social_widget">
                <h3 className="f-title f_600 t_color2 f_size_18 mb_20">Follow us</h3>
                <div className="f_social_icon">
                  <a
                    aria-label="Facebook"
                    className="facebook-icon"
                    href="https://www.facebook.com/hexactinc/"
                    target="_blank"
                    rel="nofollow noopener"
                  >
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                  <a
                    aria-label="Youtube"
                    className="youtube-icon"
                    href="https://www.youtube.com/c/HexactInc"
                    target="_blank"
                    rel="nofollow noopener"
                  >
                    <FontAwesomeIcon icon={faYoutube} />
                  </a>
                  <a
                    aria-label="LinkedIn"
                    className="linkedIn-icon"
                    href="https://www.linkedin.com/company/hexactinc"
                    target="_blank"
                    rel="nofollow noopener"
                  >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                  <a
                    aria-label="Twitter"
                    className="twitter-icon"
                    href="https://twitter.com/hexactinc"
                    target="_blank"
                    rel="nofollow noopener"
                  >
                    {/* <FontAwesomeIcon icon={faTwitter} /> */}
                    {twitterIcon}
                  </a>
                  <a
                    aria-label="Instagram"
                    className="instagram-icon"
                    href="https://www.instagram.com/hexacthq/"
                    target="_blank"
                    rel="nofollow noopener"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </div>
              </div>
              {/* <div className="d-flex flex-wrap apps-container justify-content-center">
                <a
                  aria-label="apple app store icon"
                  href="https://apps.apple.com/app/hexowatch/id1589763681"
                  target="_blank"
                  rel="noopener"
                >
                  <img src={appstore} alt="app store" />
                </a>
                <a
                  aria-label="google play market icon"
                  href="https://play.google.com/store/apps/details?id=com.hexact.hexowatch"
                  target="_blank"
                  rel="noopener"
                >
                  <img src={googleplay} alt="google play" />
                </a>
              </div> */}
              <div className="mt-3 join-hexomatic ml-auto mr-auto text-center justify-content-center">
                <div>Tap into Hexomatic,</div>
                <div>
                  the easiest no-code{' '}
                  <a href="https://hexomatic.com" target="_blank">
                    web scraper
                  </a>
                </div>{' '}
                <div>and work automation platform.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-12">
              &copy; {new Date().getFullYear()} Hexact, Inc. All rights reserved. 1250 E. Hallandale Beach Blvd., Unit
              808, Hallandale Beach, Florida 33009
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <ul className="list-unstyled f_menu text-right">
                <li>
                  <Link to="/terms-of-use">Terms of Service</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/cookie-policy">Cookie Policy</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
